/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import hc_smooth_scroll
    from '@iris.interactive/handcook/public/scripts/components/smooth-scroll/smooth-scroll.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    hc_smooth_scroll()
    new NavbarComponent()

    //Front page only
    if ($('.home').length > 0) {
        import('./services/home.service' /* webpackChunkName: "scripts/home.service" */).then(
            ({default: HomeService}) => {
                new HomeService()
            },
        )
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import('./services/layout.service' /* webpackChunkName: "scripts/layout.service" */).then(
            ({default: LayoutService}) => {
                new LayoutService()
            },
        )
    }

    // detect when eco bar is open
    if ($('[data-id="eco-bar"].eco-bar--hidden').length === 1) {
        $('body').removeClass('eco-bar-open')
    } else if (!$('body').hasClass('error404') && !$('body').hasClass('search')) {
        $('body').addClass('eco-bar-open')
    }
    $('[data-toggle="#eco-bar"]').on('click', () => {
        if ($('[data-id="eco-bar"].eco-bar--hidden').length === 1) {
            $('body').removeClass('eco-bar-open')
        } else if (!$('body').hasClass('error404') && !$('body').hasClass('search')) {
            $('body').addClass('eco-bar-open')
        }
    })
})
